
import React, { Suspense, Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from "react-router";

import { BrowserRouter, Redirect, Switch, Route, Link, NavLink } from "react-router-dom";


import { Button } from 'reactstrap';

import LoggedIn from './containers/loggedIn';
import LoggedOut from './containers/loggedOut';

import {logout} from './actions/auth';

import CONFIG from './config/config';
import axios from 'axios';

import {getUserInfo} from './actions/auth';

import ImageUploader from 'react-images-upload';
import i18n from './i18n';

import Spinner from 'react-spinner-material';


import {Helmet} from "react-helmet";

import {getInstallationGroups} from './actions/core';

import { Trans } from 'react-i18next'

import { withTranslation } from 'react-i18next';



var CONT_axios_Alarms = 0;
//var AUX_str_ALL_SLs_Alarms_Disabled =  "";




const $ = window.$;


class App extends Component // ----------------------------------------------------------------------------------------------------------------------------------------
{

	constructor ( props )
	{
		super( props );

		this.state =
						{
							location: "/",

							picture: undefined,
							username: "",
							pass1: "11111",
							pass2: "11111",
							email: "",

							picturechanged: false,
							changed: false,
							passchanged: false,
							emailchanged: false,

							cpu_version_visibility: 100,
							core_waiting: props.core_waiting,


							ALARMS_ENABLED_USER_COUNT:  0

							//str_ALL_SLs_Alarms_Disabled: ""
						};


		this.onDrop = this.onDrop.bind( this );
		this.updateMe = this.updateMe.bind(this);
		this.updatePicture = this.updatePicture.bind( this );
		this.userlevelcolor = this.userlevelcolor.bind( this );

		this.updateOptionVisibility = this.updateOptionVisibility.bind( this );

		this.showSpinner = this.showSpinner.bind( this );

	} // constructor ( props )




	checkOrientation ( )
	{
		let os = "";

		if (
				navigator.userAgent.match(/Android/i) ||
				navigator.userAgent.match(/webOS/i) || 
				navigator.userAgent.match(/iPhone/i) || 
				navigator.userAgent.match(/iPad/i) || 
				navigator.userAgent.match(/iPod/i) || 
				navigator.userAgent.match(/BlackBerry/i) || 
				navigator.userAgent.match(/Windows Phone/i)
			)
		{
			os = "mobile"
		}
		

		let orientation = "";

		if ( window.matchMedia( "(orientation: portrait)" ).matches )
		{
			orientation="portrait"
		}
		else
		{
			orientation="landscape"
		}

		let device = !!navigator.maxTouchPoints ? 'mobile' : 'computer';

		//$("#infoModal").html(orientation);
		//$("#avisos").modal();

		let devicenow = device;
		let orientationnow = orientation;

		setTimeout(
					() =>
					{
						if ( window.matchMedia( "(orientation: portrait)" ).matches )
						{
							orientation="portrait"
						}
						else
						{
							orientation="landscape"
						}

						device =  !!navigator.maxTouchPoints  ?  'mobile'  :  'computer';

						if ( 
								( os=="mobile"  &&  device=="mobile"  &&  orientation=="landscape" )  &&
								( devicenow=="mobile"  &&  orientationnow=="landscape" )
						)
						{
							//alert(this.props.i18n.t("login.no_landscape"));

							if ( window.location.href.split("/")[ window.location.href.split("/").length - 1 ] == "" )
							{
								$("#infoModalRot").html(this.props.i18n.t("login.no_landscape"));
								$("#avisoRot").modal("show");                
							}
						}
						else
						{
							$("#avisoRot").modal("hide");      
						}
					}

					, 1000

		) // setTimeout

	} // checkOrientation ( )




	componentDidMount ( )
	{

		//window.alert( "AQUI" )

		this.props.getUserInfo( this.props.auth.token );

		window.addEventListener(
									'resize',

									( ) =>
									{
										this.checkOrientation();
									}
		);

		this.checkOrientation();

		setTimeout(
					() =>
					{
						this.props.getInstallationGroups( this.props.auth.token,  true );
					}

					, 250
		);
		

		$( 'body' ).on(
						'click',

						'a.disabled',

						function ( event )
						{
							event.preventDefault();
						}
		);


		if (  CONFIG["getPreferredLanguage"]() != ""  )
		{
			//console.log(CONFIG["getPreferredLanguage"]());


			setTimeout(
							function ()
							{
								i18n.changeLanguage(  CONFIG[ "getPreferredLanguage" ]()  )
							}

							, 1000
			);
		}

		const this_ = this;

		this.timer = 
					setInterval(
									() =>
									{ 
										//console.log( "Refresh general values...." );

										if ( document.location.href.indexOf("grafica") < 0 )
										{
											this_.props.getInstallationGroups( this.props.auth.token,  true );
										}
									}

									, 1000 * 60 * 6

					); // Cada 5 min


		//ALARMS_ENABLED_USER_COUNT =  0;
		CONT_axios_Alarms = 0;

	}  // componentDidMount ( ) ------------------------------------------------------------------------------------------------------------------




	showSpinner ( )
	{
		if ( !this.props.core_waiting )
		{
			return ( <div/> )
		}

		return(
					<div style={{position: "absolute", left: "0", top: "0", width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,0.7)" , zIndex: "10"}}>

						<div style={{position: "absolute", left: "45%", top: "40%"}}>

							<Spinner radius={120} spinnerColor={"#333"} spinnerWidth={2} visible={true} />

						</div>

					</div>
		);

	} // showSpinner ( )




	updatePicture ( )
	{
		this.setState( { picture: `${CONFIG["server_url"]}/media/${this.props.auth.user.user_profile.thumbnail}` } );

	} // updatePicture ( )




	updateOptionVisibility ( option )
	{
		let cpu_version_visibility = Math.abs( Number( option ) );

		this.setState( { cpu_version_visibility: cpu_version_visibility } );

		return option;

	} // updateOptionVisibility ( option )



	userlevelcolor ( )
	{
		let style =
					{
						border: "2px solid #fc9435"
					}

		if ( this.props.auth.user.user_profile.userlevel == "USER" )
		{
			style.border="2px solid blue";
		}
		else
			if ( this.props.auth.user.user_profile.userlevel == "ADMIN" )
			{
				style.border="2px solid green";
			}
			else
			{
				style.border="2px solid #fc9435";
			}

		return style;

	} // userlevelcolor ( )



	onDrop ( pictureFiles, pictureDataURLs )
	{
		this.setState(
						{
							picture: pictureDataURLs[ pictureDataURLs.length - 1 ],

							picturechanged: true
						}
		)//

	} // onDrop ( pictureFiles, pictureDataURLs )




	updateMe ( )
	{
		let token = "Token " + this.props.auth.token;

		let info = {};


		if ( this.state.changed )
		{
			info["username"] = this.state.username;
		}

		if ( this.state.picturechanged )
		{
			info["picture"] = this.state.picture;
		}


		if ( this.state.passchanged )
		{
			info["password"] = this.state.pass1;
		}

		if ( this.state.emailchanged )
		{
			info["email"] = this.state.email;
		}


		// Actualizarme
		axios.put(
					CONFIG.api_url  +  "/users/me",

					{ info: info },

					{
						"headers":
									{
										'Content-Type': 'application/json',
										'Authorization': token
									}
					}  
		).then(
				( result ) =>
				{
					this.props.getUserInfo( this.props.auth.token );
					
					setTimeout(
								( ) =>
								{
									this.setState(
													{
														picture: `${CONFIG["server_url"]}/media/${this.props.auth.user.user_profile.thumbnail}`,

														username: this.props.auth.user.first_name,
														email: this.props.auth.user.email
													}
									);                    
								}

								, 1000
					);

				}
		).catch(
				error =>

					this.setState(
									{
										error,
										isLoading: false
									}
					)//
		);//.. .catch()

	}// updateMe ( )




	mainMenu ( this_ )
	{
		let disableIfVersionCero = "";

		if ( this.state.cpu_version_visibility < 1 )
		{
			disableIfVersionCero = "disabled";
		}


		if( this.props.auth.user.user_profile !== undefined )
		{
			if ( this.props.auth.user.user_profile.userlevel == "USER" )
			{	
				disableIfVersionCero = "disabled";
			}//
		}
		else
		{
			this.props.logout();

			setTimeout(
						( ) =>
						{
							window.document.location.href = "/";
						}

						, 2000
			);//setTimeout
		}
		
		
		let active_color = "orange";
		let non_active_color = "white";

		//--

		function getMenuImg ( url, option )
		{
			if ( this_.state.location == option )
			{
				url = url.replace( "/white/", "/orange/" );
			}

			return url;

		}// function getMenuImg ( url, option )



		//--

		return (
					<div className="col-6 main-menu">

						<ul>

							<li className="nav-item">
								<NavLink to="/" >
									<img
										src = { getMenuImg( "/img/ico/white/ico-b-casa.svg", "/" ) }

										id="home"

										data-toggle="tooltip"
										data-placement="bottom"
										title = { this_.props.i18n.t( "app.home" ) }
										alt = { this_.props.i18n.t( "app.home" ) }
									/>
								</NavLink>
							</li>
							
							
							<li className="nav-item">
								<Link to="/instalaciones" >
									<img
										src = { getMenuImg( "/img/ico/white/ico-b-marcas.svg", "/instalaciones" ) }

										data-toggle="tooltip"
										data-placement="bottom"
										title= { this_.props.i18n.t( "app.installation" ) }
										alt= { this_.props.i18n.t( "app.installation" ) }
									/>
								</Link>
							</li>
							
							
							<li className="nav-item">
								<Link to="/farolas" >
									<img
										src = { getMenuImg("/img/ico/white/ico-b-indice.svg", "/farolas" ) }

										data-toggle="tooltip"
										data-placement="bottom"
										title= { this_.props.i18n.t( "app.streetlights" ) }
										alt= { this_.props.i18n.t( "app.streetlights" ) }
									/>
								</Link>
							</li>
											
							<li className="nav-item">
								<Link to="/calendar" className={disableIfVersionCero}>
									<img
										src = { getMenuImg( "/img/ico/white/ico-b-calendar.svg", "/calendar" ) }

										data-toggle="tooltip"
										data-placement="bottom"
										title= { this_.props.i18n.t( "app.calendar" )}
										alt= { this_.props.i18n.t( "app.calendar" ) }
									/>
								</Link>
							</li>    

							
							<li className="nav-item">
								<Link to="/perfiles" className={disableIfVersionCero}>
									<img
										src = { getMenuImg( "/img/ico/white/ico-b-settings.svg", "/perfiles" ) }

										data-toggle="tooltip"
										data-placement="bottom"

										title= { this_.props.i18n.t( "app.profiles" ) }
										alt= { this_.props.i18n.t( "app.profiles" ) }
									/>
								</Link>
							</li>

							<li className="nav-item">
								<Link to="/graficador" className={disableIfVersionCero}>
									<img
										src = { getMenuImg( "/img/ico/white/ico-b-grafica.svg", "/graficador" ) }

										data-toggle = "tooltip"
										data-placement = "bottom"
										title = { this_.props.i18n.t( "app.chart" ) }
										alt = { this_.props.i18n.t( "app.chart" ) }
									/>
								</Link>
							</li>

							
							<li className="nav-item">
								<Link to="/alarmas" className = {disableIfVersionCero}>
								{
									(  this.props.auth.user  &&  this.props.auth.user.summary_info  &&  this.props.auth.user.summary_info.unread_alarms > 0  )  &&

									<span className = "notificacion">
										{
											(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )
											?
												this.props.auth.user.summary_info.unread_alarms
											:
												this.state.ALARMS_ENABLED_USER_COUNT
										}
									</span>
								}
								
									<img
										src = { getMenuImg( "/img/ico/white/ico-b-carpeta.svg", "/alarmas" ) }

										data-toggle = "tooltip"
										data-placement = "bottom"

										title =  { this_.props.i18n.t( "app.alarms" ) }
										alt = { this_.props.i18n.t( "app.alarms" ) }
									/>
								</Link>
							</li>


							<li className="nav-item">
								<Link to="/historial" className={disableIfVersionCero}>
									<img
										src={ getMenuImg( "/img/ico/white/ico-b-dashboard.svg", "/historial" ) }

										data-toggle="tooltip"
										data-placement="bottom"
										title = { this_.props.i18n.t( "app.history" ) }
										alt = { this_.props.i18n.t( "app.history" ) }
									/>
								</Link>
							</li>




							{
							/*
							(   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  ||  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )   )  &&

							<li className="nav-item">
								<Link to="/informes" className={disableIfVersionCero}>
									<img
										src={ getMenuImg( "/img/ico/white/ico-b-informe.svg", "/informes" ) }

										data-toggle="tooltip"
										data-placement="bottom"

										title = { this_.props.i18n.t( "app.reports" ) }
										alt = { this_.props.i18n.t( "app.reports" ) }
									/>
								</Link>
							</li>
							*/
							}



							{
							(   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  ||  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )   )  &&

							<li className="nav-item">
								<Link to="/informes" className={disableIfVersionCero}>
									<img
										src={ getMenuImg( "/img/ico/white/ico-b-informe.svg", "/informes" ) }

										data-toggle="tooltip"
										data-placement="bottom"

										title = { this_.props.i18n.t( "app.reports" ) }
										alt = { this_.props.i18n.t( "app.reports" ) }
									/>
								</Link>
							</li>
							
							}

						</ul>

					</div>
		);// return()

	}// mainMenu ( this_ )




	userMenu ( )
	{    
		return (
					<div className="col">
						<ul className="nav nav-user">

							<li className="nav-item dropdown">

								<a
									className="nav-link dropdown-toggle"
									data-toggle="dropdown"
									href="#"
									role="button"
									aria-haspopup="true"
									aria-expanded="false"
								>
								{ 
									this.props.auth.user.user_profile &&

									<img
										className="user-avatar"

										src = { `${CONFIG["server_url"]}/media/${this.props.auth.user.user_profile.thumbnail}` }

										style= { this.userlevelcolor() }
										alt=""
									/>  
								}
									
									<div style={{float: "right", marginTop: "8px", marginLeft: "-20px"}} className="d-none d-md-block">
								{
									this.props.auth.user.first_name &&
									this.props.auth.user.first_name
								}
								{
									!this.props.auth.user.first_name &&
									this.props.auth.user.username
								}
									</div>
								</a>

								<ul className="dropdown-menu">
									<li>
										<a
											className="dropdown-item"
											href="#"
											onClick =
														{
															( e ) =>
															{
																e.preventDefault();
																$( "#perfil" ).modal();
															}
														}
										>
											<Trans>app.profile</Trans>
										</a>
									</li>

									<hr/>

									<li>
										<a
											className = "dropdown-item"
											href = "#"
											onClick =
														{
															( ) =>
															{
																this.setState( {picture: ""} );
																this.props.logout();

																setTimeout(
																			( ) =>
																			{
																				document.location.href="/";
																			}

																			, 500
																);
															}
														}
										>
											Logout
										</a>
									</li>

								</ul>

							</li>
						</ul>

					</div>

		)// return()

	}// userMenu ( )




	render ( )
	{
		let current_year = new Date().getFullYear();

		if ( this.state.picture == undefined && this.props.auth.user )
		{
			if ( this.props.auth.user.user_profile )
			{
				this.setState(
								{
									picture: `${CONFIG["server_url"]}/media/${this.props.auth.user.user_profile.thumbnail}`,

									username: this.props.auth.user.first_name,
									email: this.props.auth.user.email
								}
				);//.setState()      
			}//if
		}//if



		//---------


		if (   CONT_axios_Alarms  <=  0   )
		{

			let str_FILTER_VAL =  "false";			//(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )	?	"true"	:	"false";

			//--------

			axios.get(
							CONFIG.api_url	+	"/alarms"	+
															"?page=1"	+
															"&filtro="	+

															'{'			+
																'"type":"filter",'	+
																
																'"value":'				+
																'{'						+
																	'"sortField":null,'	+
																	
																	'"filters":'		+
																	'{'					+

																		'"disabled_alarms":'					+
																		'{'										+
																			'"filterVal":'						+	str_FILTER_VAL	+	','		+
																			'"filterType":"SELECT",'			+
																			'"comparator":"=",'					+
																			'"caseSensitive":false'				+
																		'}'										+

																	'},'										+
																	
																	'"page":0,'									+
																	'"sizePerPage":100'							+

																'}'												+

															'}'
							,																//'&fromDate=1587748699&toDate=1653377439',
																							//  "filterVal":false,  -->  AHORA: "filterVal":true,
							{
								"headers":
											{
												'Content-Type': 'application/json',
												'Authorization': "Token " + this.props.auth.token
											}
							}
			).then(
						( result ) =>
						{


							//AUX_str_ALL_SLs_Alarms_Disabled =  JSON.stringify(  result.data.info.all_streetlights  );


							//window.alert(  AUX_str_ALL_SLs_Alarms_Disabled  );


							//AUX_str_ALL_SLs_Alarms_Disabled =  AUX_str_ALL_SLs_Alarms_Disabled.toLowerCase();



							//window.alert(  AUX_str_ALL_SLs_Alarms_Disabled  );


							//console.log(  "HA LLEGADOF AQUÍ, ALARMAS"  );
							//console.log(  AUX_str_ALL_SLs_Alarms_Disabled  );
							//console.log(  result.data  );



							this.state.ALARMS_ENABLED_USER_COUNT =  result.data.count;

							//this.state.str_ALL_SLs_Alarms_Disabled =  AUX_str_ALL_SLs_Alarms_Disabled;

												
							this.setState(	
											{

												ALARMS_ENABLED_USER_COUNT:		result.data.count,

												//str_ALL_SLs_Alarms_Disabled: 	AUX_str_ALL_SLs_Alarms_Disabled,
											}
							);//





							//CONT_axios_Alarms++;
							
						}

			);// ... .then()



			//-----

			CONT_axios_Alarms++;


		}// if (   CONT_axios_Alarms == 0   )

		

		//--------------------------------------------------------------------------------------------------------------


		return (
					<BrowserRouter>

						<div className="App">

							<Helmet>
								<meta charSet="utf-8" />
								<title>{this.props.t("app.title")}</title>
								<meta name="description" content = { this.props.i18n.t( "app.description" ) } />
								<link rel="canonical" href="https://apps.ilumek.com" />
							</Helmet>



							<header>

								<div className="container-fluid">
									<nav className="navbar navbar-expand-md navbar-dark fixed-top bg-ilumek" id="topNavbar">

									<div className="col">
										<a className="navbar-brand" href="#">
											<img src="/img/logotipo-light.svg" height="25px" alt="ILUMEK" />
										</a>
									</div>

									{
									( this.props.auth.loggedIn )  &&  this.mainMenu( this )
									}

									<div className="col d-none d-md-block">
										<select
												className="form-control select language-select "
												style={{width: 80, backgroundColor: "#212529", height: 40, color: "white"}}

												onChange =
															{
																( e ) =>
																{
																	i18n.changeLanguage( e.target.value );
																	CONFIG["setPreferredLanguage"]( e.target.value );

																	setTimeout(
																				( ) =>
																				{
																					window.location.reload();
																				}

																				, 300
																	)//setTimeout()
																}
															}
										>
											{
											CONFIG.idiomas.map(
																	( item, index ) =>
																	{
																		let language_code = Object.keys( item )[0];

																		return	(
																					<option
																						value={language_code}
																						selected = { ( CONFIG["getPreferredLanguage"]() == language_code )  ?  "selected"  :  "" }
																					>
																						{language_code}
																					</option>
																				)
																	}
											)//.map()
											}
										</select>
									</div>

									{
									( this.props.auth.loggedIn )  &&  this.userMenu( this )
									}

									</nav>
								</div>

							</header>
							


							
							<div style={{marginTop: "48px", marginBottom: "0px"}}>
								{
								( !this.props.auth.loggedIn )  &&

								<LoggedOut parent={this}/>
								}

								{
								( this.props.core_waiting == false  &&  this.props.auth.loggedIn )  &&

								<Suspense fallback="loading">

									<div id="main_container">
										<LoggedIn parent={this}/>
									</div>

								</Suspense>
								}

								{
								( this.props.core_waiting  &&  this.props.auth.loggedIn )  &&  this.showSpinner()
								}
							</div>


							<div id="avisos" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
								<div className="modal-dialog modal-xl modal-dialog-centered">
									<div className="modal-content">

										<div className="modal-header">

											<h5 className="modal-title"></h5>

											<button type="button" className="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">×</span>
											</button>

										</div>

										<div className="modal-body" id="infoModalLegal" style={{minHeight: "80px"}}>
										</div>

										<div className="modal-footer">
											<center>
												<button
													type="button"
													style={{width: "100%"}}
													data-dismiss="modal"
													className="btn btn-orange"
												>
													Ok
												</button>
											</center>
										</div>

									</div>
								</div>
							</div>


							<div id="avisoRot" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
								<div className="modal-dialog modal-xl modal-dialog-centered">
									<div className="modal-content">

										<div className="modal-body" id="infoModalRot" style={{minHeight: "40px", marginTop: "10px"}}>
											-----------
										</div>

										<div className="modal-footer">
											<button
													type="button" 
													data-dismiss="modal"
													className="btn btn-orange"
											>
												Ok
											</button>
										</div>

									</div>
								</div>
							</div>



							<div id="perfil" className="modal"  role="dialog" aria-hidden="false">
								<div className="modal-dialog modal modal-dialog-centered wide">
									<div className="modal-content">


										<div className="modal-header">
											<h5 className="modal-title">
												<Trans>app.profile</Trans>
											</h5>

											<button type="button" className="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">
													×
												</span>
											</button>

										</div>

										<div className="modal-body" id="infoModal">
											<form >

												<div className="form-row row align-items-left">

													<div className="col-auto">
														<ImageUploader
																withIcon={true}
																buttonText='Choose images'
																className="mb-2"
																onChange={this.onDrop}
																imgExtension={['.jpg', '.gif', '.png', '.gif']}
																maxFileSize={5242880}
														/>
													</div>

													<div className="mb-2">
														{ 
														this.state.picture  &&
														<img src={this.state.picture} style={{maxWidth: "200px", maxHeight: "200px"}}/>
														}
													</div>

												</div>


												<div className="form-row align-items-left">
													<div style={{height: "20px"}}/>
												</div>


												<div className="form-group row">

													<label for="inputName" className="col-sm-2 col-form-label">
														<Trans>app.name</Trans>
													</label>

													<div className="col-sm-10">
														<input
															type="email"
															className="form-control" 
															id="inputName"
															placeholder="Nombre" 
															value = { this.state.username }

															onChange =
																		{
																			( e ) =>
																			{
																				this.setState(
																								{
																									username: e.target.value,
																									changed: true
																								}
																				)//.setState()
																			}
																		}
														/>
													</div>

												</div>


												<div className="form-group row">
													<label for="inputPass1" className="col-sm-2 col-form-label">
														Pass1
													</label>

													<div className="col-sm-10">
														<input
															type="password"
															className="form-control"
															id="inputPass1" 
															placeholder="Password1"
															value={this.state.pass1}

															onChange =
																		{
																			( e ) =>
																			{
																				this.setState(
																								{
																									pass1: e.target.value,
																									passchanged: true
																								}
																				)//.setState()
																			}
																		}
														/>
													</div>
												</div>


												<div className="form-group row">
													<label for="inputPass2" className="col-sm-2 col-form-label">
														Pass2
													</label>

													<div className="col-sm-10">
														<input
															type="password"
															className="form-control" 
															id="inputPass2" 
															placeholder="Password2"
															value={this.state.pass2}

															onChange =
																		{
																			( e ) =>
																			{
																				this.setState(
																								{
																									pass2:	e.target.value,
																									passchanged: true
																								}
																				)//
																			}
																		}
														/>
													</div>
												</div>



												<div className="form-group row">

													<label for="inputEmail" className="col-sm-2 col-form-label">
														Email
													</label>

													<div className="col-sm-10">
														<input
															type="email"
															className="form-control"

															id="email" 
															placeholder="Email"
															value={this.state.email}

															onChange =
																		{
																			( e ) =>
																			{
																				this.setState(
																								{
																									email: e.target.value,
																									emailchanged: true
																								}
																				)//.setState()
																			}
																		}
														/>
													</div>
												</div>


											</form>
										</div>


										<div className="modal-footer">
											<button
													type="button" 
													data-dismiss="modal"
													className="btn btn-orange"

													disabled = { 
																	!(
																		this.state.changed  ||
																		(  this.state.pass1==this.state.pass2  &&  this.state.passchanged  )  ||
																		this.state.picturechanged  ||
																		this.state.emailchanged 
																	)
																}

													onClick =
																{
																	( ) =>
																	{
																		this.updateMe();
																	}
																}
											>
												Ok
											</button>
										</div>


									</div>
								</div>
							</div>



							<footer className="footer">

								<div className="container-fluid">
									<div className="row" style={{marginTop: "3px"}}>


										<div className="footcol-1 col-sm-12 col-md-3 d-none d-md-block">
											<div style={{verticalAlign: "middle"}}>

												<a href="https://www.ekiona.com/">
													© {current_year} EKIONA
												</a>

											</div>
										</div>


										<div className="col-sm-12 d-sm-block d-md-none">
											<select
													className="form-control select language-select"
													style={{backgroundColor: "#212529", height: 30, color: "white"}}

													onChange =
																{
																	( e ) =>
																	{
																		i18n.changeLanguage( e.target.value );
																		CONFIG["setPreferredLanguage"]( e.target.value );
																	}
																} 
											>
											{
												CONFIG.idiomas.map(
																	( item, index ) =>
																	{
																		let language_code = Object.keys( item )[0];

																		return	(
																					<option
																							value = {language_code}
																							selected = { ( CONFIG["getPreferredLanguage"]() == language_code )  ?  "selected"  :  "" }
																					>
																						{language_code}
																					</option>
																				)
																	}
												)//.map()
											}
											</select>
										</div>


										<div className="footcol-2 col-sm-12 col-md-6">
											<div style={{verticalAlign: "middle"}}>
												Polígono Industrial Abendaño · Sarobe, 6 · 20800 Zarautz
											</div>
										</div>


										<div className="footcol-3 col-sm-12 col-md-3">
											<div style={{verticalAlign: "middle"}}>

												<p id="legal_legal" style={{display: "none"}}>
													<Trans>legal.legal</Trans>
												</p>

												<a
													href="#a"

													onClick =
																{
																	( ) =>
																	{
																		$( "#infoModalLegal" ).html(  this.props.i18n.t( "legal.legal" )  );
																		$( "#avisos" ).modal();
																	}
																}
												>
													<Trans>footer.legal_notice</Trans>
												</a>

												&nbsp;&nbsp;·&nbsp;&nbsp;

												<p id="legal_cookies" style={{display: "none"}}>
													<Trans>legal.cookies</Trans>
												</p>

												<a
													href="#a"

													onClick =
																{
																	( ) =>
																	{
																		$( "#infoModalLegal" ).html(  this.props.i18n.t( "legal.cookies" )  );
																		$( "#avisos" ).modal();
																	}
																}
												>
													<Trans>footer.cookies</Trans>
												</a>

												&nbsp;&nbsp;·&nbsp;&nbsp;

												<a href="mailto:info@ilumek.com">
													info@ilumek.com
												</a>

											</div>
										</div>


									</div>
								</div>

							</footer>

						</div>

					</BrowserRouter>

		);// return()

	} //render ( )


} // class App extends Component


//-------

const mapStateToProps = 
						function ( state )
						{
							return	{
										auth: state.auth,
										core: state.core,
										core_waiting: state.core.waiting_for_info
									}
						}


const mapDispatchToProps =
							( dispatch ) =>
											{
												return	{
															logout: () => 
																		{      
																			dispatch (logout() );
																		},

																		getUserInfo: ( token ) =>
																								{
																									dispatch( getUserInfo(token) );
																								},

																		getInstallationGroups:
																								( a,b ) =>
																											{
																												dispatch(  getInstallationGroups( a, b )  )
																											},
														}
											}


export default withRouter(  connect( mapStateToProps, mapDispatchToProps ) (withTranslation()(App))  );
